<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div *ngIf="homePage_Menu" class="flex flex-col flex-auto items-center w-full bg-gray-200">
    <!-- Header -->
    <div class="nav-wrapper" [class.menu-open]="navMenuIsOpen" [class.search-open]="searchIsOpen">
        <div class="nav">
            <div class="container nav-container">
                <!-- Nav logo -->
                <a (click)="routToHome(homePage_Menu.mainLogo.image.targetURL)" class="nav-logo-wrapper cursor-pointer">
                    <img alt="Brand logo" src="assets/images/logo/newlogo.png" />
                    <b>Mate Infotech STS</b> </a>

                <!-- Mobile only menu -->
                <div class="nav-mobile-menu">
                    <a class="nav-desktop-btn cursor-pointer" [matMenuTriggerFor]="guestMenu" *ngIf="!loginDisplay">
                        <img src="assets/icons/white/user-black.svg" class="h-7 w-7" alt="User" />
                    </a>
                    <mat-menu #guestMenu="matMenu" xPosition="before" class="mt-8 border">
                        <login-confirmation></login-confirmation>
                    </mat-menu>
                    <a routerLink="cart" class="nav-desktop-btn">
                        <span class="relative -top-1 right-0 left-0 flex items-center justify-center h-3">
                            <span
                                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 rounded-full bg-sdc-red text-indigo-50 text-xs font-medium">
                                {{ cartItemCount }}
                            </span>
                        </span>
                        <img src="assets/icons/school-bag.svg" alt="Cart" class="h-7 w-7 -mt-3 p-1" />
                    </a>
                </div>
                <!-- Navigation main menu - Responsive -->
                <div class="nav-menu">
                    <!-- Top section -->
                    <h1 class="nav-menu-desktop-heading">

                    </h1>
                    <div class="nav-menu-section nav-menu-main-section">
                        <div class="nav-menu-link">
                            <span class="nav-menu-link-text font-light">Email: info@mateinfotechsts.com</span>
                        </div>
                        <div class="border-r border-[#43467F]">
                        </div>
                        <a routerLink="/about-us" routerLinkActive="active-link" class="nav-menu-link">
                            <span class="nav-menu-link-text font-light">About Us</span>
                        </a>
                    </div>

                </div>



                <div *ngIf="showHeaderContent()" class="nav-desktop-buttons">
                    <div class="nav-desktop-shop-wrapper" style="inline-size: min-content">
                        <a routerLink="" class="nav-desktop-btn hidden md:block"><img src="assets/icons/white/shop.svg"
                                alt="Shop" /></a>
                        <div class="nav-desktop-content max-w-60 lg:max-w-none hidden md:block">
                            <h3 class="nav-desktop-heading blink_me text-[18px]">
                                Opening ceremony offers on training
                                <br> 
                                First batch (size-10) - 100% free. Contact us for free registration..
                            </h3>
                            <span class="nav-desktop-text font-medium text-gray10">
                                Now offline training locations are limited :
                                <location-finder></location-finder>
                            </span>
                        </div>
                    </div>

                    <a routerLink="" class="nav-desktop-btn" *ngIf="loginDisplay" title="Click here for logout"><img
                            src="assets/icons/white/user.svg" alt="User" />
                    </a>

                    <div *ngIf="loginDisplay">
                        <details x-data x-ref="dropdown" class="account-dropdown relative inline-block text-left">
                            <summary>
                                <label class="text-[12px] leading-tight">Hi {{ customerName | titlecase }}</label>
                                <div
                                    class="inline-flex cursor-pointer items-center text-[12px] leading-tight justify-start w-full font-medium shadow-sm text-white focus:outline-none">
                                    Account
                                    <!-- Heroicon name: solid/chevron-down -->
                                    <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                            </summary>

                            <details-menu role="menu"
                                class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[12]">
                                <div class="py-1" role="none">
                                    <a href="/wishlist"
                                        class="block px-4 py-2 font-medium text-black hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem">Wishlist</a>
                                    <!-- <a href="/notifications"
                                        class="block px-4 py-2 font-medium text-black hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem">Notification</a> -->
                                    <a href="/purchases"
                                        class="block px-4 py-2 font-medium text-black hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem">My Purchases</a>
                                    <a href="/account"
                                        class="block px-4 py-2 font-medium text-black hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem">View Profile</a>
                                    <div class="py-2 mx-4 px-4 mt-2 mb-4 rounded-md bg-gray4 flex items-center gap-4">
                                        <img class="w-6 h-6" src="../../../../../../assets/icons/star-black.svg" />
                                        <div class="flex-1">
                                            <div class="text-black font-medium">
                                                My Super Star Rewards
                                            </div>
                                            <div class="text-black text-sm">
                                                You have {{rewardDetails?.points_balance}} points
                                            </div>
                                        </div>
                                    </div>

                                    <span (click)="logout()"
                                        class="block px-4 py-2 font-medium text-black hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                                        role="menuitem">Log Out</span>
                                </div>
                            </details-menu>
                        </details>
                    </div>
                    <a class="nav-desktop-btn cursor-pointer" [matMenuTriggerFor]="guestMenu" *ngIf="!loginDisplay">
                        <img src="assets/icons/white/user-black.svg" class="h-7 w-7" alt="User" />
                    </a>
                    <mat-menu #guestMenu="matMenu" xPosition="before" class="mt-8 border">
                        <login-confirmation></login-confirmation>
                    </mat-menu>
                    <a routerLink="cart" class="nav-desktop-btn">
                        <span class="relative -top-3 right-0 left-0 flex items-center justify-center h-3">
                            <span
                                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 rounded-full bg-sdc-red text-indigo-50 text-xs font-medium">
                                {{ cartItemCount }}
                            </span>
                        </span>
                        <img src="assets/icons/school-bag.svg" alt="Cart" class="h-7 w-7 -mt-4" />
                    </a>
                </div>

                <div *ngIf="currentPage == '/signup'" class="flex items-center justify-center">
                    <div class="font-bold text-4xl text-white">
                        Create an account
                    </div>
                </div>
            </div>
        </div>
        <div class="nav">
            <div class="container nav-container">
                <!-- Searchbox -->
                <div *ngIf="showHeaderContent()" class="searchbox ml-50 hidden sm:block">
                    <ecomm-search [isMobile]="false" (querySuggestion)="onQuerySuggestion($event)"></ecomm-search>
                </div>

                <div><span class="blink_me text-sdc-red text-[18px] font-bold">Software Training & Service </span></div>
                <div> <span class="nav-desktop-heading">Make a call/Email to know more : +91 7385733488</span></div>
            </div>
        </div>
        <!-- Tabs -->

        <div *ngIf="showHeaderContent()"
            class="relative flex justify-center bg-white h-16 leading-[140%] border-b-2 border-b-sdc-navy">
            <div *ngIf="isScreenSmall" class="flex items-center px-1" (click)="onLeftMove()"><img _ngcontent-min-c248=""
                    src="assets/icons/blue/left_arrow.svg" alt="" class="w-3 h-3"></div>
            <div id="menudiv" class="container flex items-center overflow-x-scroll bg-white my-3 mx-2 gap-7">

                <div *ngFor="
                        let link of homePage_Menu.megaMenu;
                        let parentIndex = index
                    "
                    class="group text-sdc-navy border-b-4 border-b-white hover:border-b-sdc-yellow hover:border-b-4 cursor-pointer">

                    <div class="w-max text-[16px] md:text-[18px] text-center font-medium"
                        (mouseover)="hoverMobileMegaMenu(parentIndex, link?.targetURL)"
                        (click)="openMobileMegaMenu(parentIndex, link?.targetURL)">

                        <span *ngIf="parentIndex == 0; else else_content" style="color: red;">{{ link.title }}</span>
                        <ng-template #else_content>{{ link.title }}</ng-template>
                    </div>
                    <!-- MEGA MENU Desktop version -->
                    <div *ngIf="link.subMenus.length > 0"
                        class="hidden sm:block header-sub-content absolute top-5 md:top-5 transition group-hover:translate-y-3 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50  w-[740px] transform">
                        <div class="border-2 border-sdc-navy rounded-xl relative top-6 bg-white shadow-xl w-full">
                            <div class="relative z-10">
                                <div class="h-[460px] relative grid grid-cols-10 gap-2">
                                    <div class="h-full overflow-y-scroll col-span-4 border-r border-gray5">
                                        <ul class="text-[16px] px-4 py-2">
                                            <li class="" *ngFor="
                                                    let subMenu of link.subMenus;
                                                    let i = index
                                                " [attr.data-index]="i">
                                                <a (click)="
                                                        toggleMegaMenuItem(
                                                            parentIndex,
                                                            i
                                                        )
                                                    " [ngClass]="{
                                                        'bg-gray4':
                                                            openMegaMenuIndex.subIndex ==
                                                                i &&
                                                                openMegaMenuIndex.parentIndex ==
                                                                    parentIndex
                                                    }"
                                                    class="font-bold flex text-[18px] justify-between items-center px-4 py-2 -mx-2 rounded-lg hover:bg-gray5 transition ease-in-out duration-300 text-gray10">
                                                    <div>
                                                        {{ subMenu?.title }}
                                                    </div>
                                                    <img class="h-4 w-4" src="assets/icons/chevron-left.svg" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div *ngIf="openMegaMenuIndex.parentIndex != null"
                                        class="h-full overflow-y-scroll col-span-6">
                                        <ul class="text-[16px] px-4 flex flex-col gap-0 py-2">
                                            <li>
                                                <a (click)="navigatetoAdvSearch(
                                                    link.subMenus[
                                                    openMegaMenuIndex.parentIndex ==
                                                    parentIndex
                                                    ? openMegaMenuIndex.subIndex
                                                    : 0
                                                    ]?.handle,
                                                link.subMenus[
                                                        openMegaMenuIndex.parentIndex ==
                                                        parentIndex
                                                        ? openMegaMenuIndex.subIndex
                                                        : 0
                                                        ]?.handle,
                                                        link.subMenus[
                                                                    openMegaMenuIndex.parentIndex ==
                                                                    parentIndex
                                                                        ? openMegaMenuIndex.subIndex
                                                                        : 0
                                                                ]?.categoryOverview,
                                                                link.subMenus[
                                                    openMegaMenuIndex.parentIndex ==
                                                    parentIndex
                                                    ? openMegaMenuIndex.subIndex
                                                    : 0
                                                    ]?.title)"
                                                    class="block font-normal px-4 hover:text-sdc-red -mx-2 py-2 text-gray10">
                                                    <div>
                                                        &lt;&lt;
                                                        {{
                                                        link.subMenus[
                                                        openMegaMenuIndex.parentIndex ==
                                                        parentIndex
                                                        ? openMegaMenuIndex.subIndex
                                                        : 0
                                                        ]?.title
                                                        }}>>
                                                    </div>
                                                </a>
                                            </li>
                                            <li *ngFor="
                                                    let child of link.subMenus[
                                                        openMegaMenuIndex.parentIndex ==
                                                        parentIndex
                                                            ? openMegaMenuIndex.subIndex
                                                            : 0
                                                    ]?.children
                                                ">
                                                <div>
                                                    <div class="inline-block">
                                                        <img [src]="child?.image?.asset._ref"
                                                            style="height: 40px; width: 40px;" />
                                                    </div>
                                                    <div class="inline-block">
                                                        <a class="block font-normal px-4 hover:text-sdc-red -mx-2 py-2 text-gray10"
                                                            [routerLink]="" (click)="
                                                            navigatetoAdvSearch(
                                                                child.imageMobile.handle ,
                                                                child.imageMobile.handle,
                                                                child.categoryOverview,
                                                                child.title
                                                            )
                                                        ">
                                                            <div>
                                                                {{ child.title }}
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isScreenSmall" class="flex items-center px-1" (click)="onRightMove()"><img
                    _ngcontent-min-c248="" src="assets/icons/blue/right_arrow.svg" alt="" class="w-3 h-3"></div>
        </div>
        <!-- Account section -->
        <div [ngClass]="{
                hidden: !accountDisplay,
                'absolute h-screen w-full': !accountDisplay
            }">
            <div *ngIf="!loginDisplay" class="h-screen w-full flex flex-col items-center justify-center bg-white">
                <div class="font-bold -mt-56 text-[18px]">Hi guest</div>
                <div class="font-light mt-2 text-[15px]">
                    Log in for a more personalised experience
                </div>
                <button mat-flat-button class="bg-sdc-navy mt-24 w-48 text-white" (click)="login()">
                    Log in
                </button>
                <button mat-flat-button class="bg-transparent rounded text-black mt-3 w-48 bordered-btn"
                    (click)="signUp()">
                    Sign up
                </button>
            </div>
            <div *ngIf="loginDisplay" class="absolute h-screen w-full flex flex-col bg-white">
                <div class="px-2 pt-[1px] mega-menu-container">
                    <div class="h-full overflow-y-scroll px-2 pb-2">
                        <div class="flex items-center mt-10">
                            <img class="w-9 h-9 mr-2" src="assets/icons/blue/user.svg" alt="User" />
                            <div class="text-primaryNavy text-2xl font-medium">
                                Hi {{ customerName | titlecase }}
                            </div>
                        </div>
                        <ul class="text-[14px] mt-4 px-2 pt-3 rounded-lg bg-[#F8F7F0]">
                            <li class="px-2 py-2">
                                <a href="/account"
                                    class="font-medium flex justify-start pb-3 border-gray-300 border-b items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/home.svg" />
                                    <div>Account</div>
                                </a>
                            </li>
                            <li class="px-2 py-2">
                                <a href="/profile"
                                    class="font-medium flex justify-start pb-3 border-gray-300 border-b items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/user.svg" />
                                    <div>My profile</div>
                                </a>
                            </li>
                            <li class="px-2 py-2">
                                <a href="/purchases"
                                    class="font-medium flex justify-start pb-3 border-gray-300 border-b items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/order.svg" />
                                    <div>My purchases</div>
                                </a>
                            </li>
                            <li class="px-2 py-2">
                                <a href="/address"
                                    class="font-medium flex justify-start pb-3 border-gray-300 border-b items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/location.svg" />
                                    <div>My address</div>
                                </a>
                            </li>
                            <!-- <li class="px-2 py-2">
                                <a 
                                    class="font-medium flex justify-start pb-3 border-gray-300 border-b items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/credit-card.svg" />
                                    <div>My payment method</div>
                                </a>
                            </li> -->
                            <li class="px-2 py-2">
                                <a href="/my-store"
                                    class="font-medium flex justify-start pb-3 border-gray-300 border-b items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/shop.svg" />
                                    <div>My store</div>
                                </a>
                            </li>
                            <li class="px-2 pt-2">
                                <a href="/wishlist"
                                    class="font-medium flex justify-start pb-3 items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/wishlist.svg" />
                                    <div>Wishlist</div>
                                </a>
                            </li>
                        </ul>

                        <ul class="text-[14px] mt-4 px-2 pt-3 rounded-lg bg-[#F8F7F0]">
                            <li class="px-2 py-2">
                                <a (click)="logout()"
                                    class="cursor-pointer font-medium flex justify-start pb-3 items-center text-sdc-navy">
                                    <img class="h-4 w-4 mr-3" src="assets/icons/profile/log-out.svg" />
                                    <div>Log Out</div>
                                </a>
                            </li>
                        </ul>

                        <ul class="text-[14px] mt-4 p-2 mb-4 rounded-lg bg-[#F4F6F8]">
                            <li class="px-2 py-2">
                                <a class="font-normal flex justify-start items-center text-gray9">
                                    <div>FAQs</div>
                                </a>
                            </li>
                            <li class="px-2 py-2">
                                <a href="https://support.stardiscountchemist.com.au/knowledge/online-delivery"
                                    class="font-normal flex justify-start items-center text-gray10">
                                    <div>Online Delivery</div>
                                </a>
                            </li>
                            <li class="px-2 py-2">
                                <a href="https://support.stardiscountchemist.com.au/knowledge/click-and-collect"
                                    class="font-normal flex justify-start items-center text-gray10">
                                    <div>Click and Collect</div>
                                </a>
                            </li>
                            <li class="px-2 py-2">
                                <a href="https://support.stardiscountchemist.com.au/knowledge/refunds-and-returns"
                                    class="font-normal flex justify-start items-center text-gray10">
                                    <div>Refund and Returns</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- MOBILE Searchbox -->
        <!-- <div *ngIf="showHeaderContent()" class="block sm:hidden">
            <ecomm-search [isMobile]="isMobileMenuActive" (querySuggestion)="onQuerySuggestion($event)"></ecomm-search>
        </div> -->
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full">
        <div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-lg sm:overflow-hidden bg-default">

            <router-outlet></router-outlet>
            <explore-category *ngIf="true && !checkNoDataFound"></explore-category>
            <div class="flex-auto -mt-[80px] py-6 px-2 sm:px-5 md:px-6 lg:px-20 min-h-screen" *ngIf="checkNoDataFound">
                <div class="flex flex-col justify-center items-center mt-[60px] md:mt-[100px] lg:mt-[130px]">
                    <img class="w-[90px] lg:w-[120px] h-[90px] lg:h-[120px]" src="assets/icons/no_result.svg" />
                    <h5 class="text-lg md:text-3xl font-semibold mb-2 mt-9">
                        No result found for "{{ searchQueryText() }}
                    </h5>
                    <p class="font-light text-sm md:text-lg">
                        Try
                        <a href="/home" class="text-blue-500">clearing</a>
                        filters or adjusting your search
                    </p>
                </div>
            </div>
            <div class="grid grid-cols-2 md:grid-cols-3 gap-[8px] md:gap-[20px]"></div>
        </div>
    </div>

    <!-- Footer -->
    <div class="pb-[80px] sm:pb-0 relative flex flex-0 justify-center w-full z-49  print:hidden"
        style="background-color: #FAE910">

        <div class="flex flex-col items-center w-full container">
            <div class="flex flex-wrap nav-desktop-heading w-full mx-2 border-y border-[#43467F] py-10">
                <div class="w-full sm:w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">

                    </div>
                    <div class="flex flex-wrap items-center gap-1">

                    </div>

                </div>
                <div class="w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">
                        <a class="text-xl sm:text-2xl font-medium">{{
                            footer?.submenu_header_column1
                            }}</a>
                    </div>
                    <div *ngFor="let link of footer?.submenu_column1_items">
                        <div (click)="isPathAbsolute(link?.targetURL)"
                            class="text-gray9 text-md sm:text-xl py-1 hover:cursor-pointer">
                            {{ link?.title }}
                        </div>
                        <!-- (click)="isPathAbsolute(link?.targetURL)" -->
                    </div>
                </div>
                <div class="w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">
                        <a class="text-xl sm:text-2xl font-medium">{{
                            footer?.submenu_header_column2
                            }}</a>
                    </div>

                    <div *ngFor="let link of footer?.submenu_column2_items">
                        <div (click)="isPathAbsolute(link?.targetURL)"
                            class="text-gray9 text-md sm:text-xl py-1 hover:cursor-pointer">
                            {{ link?.title }}
                        </div>
                        <!--  *ngIf="link?.title=='Contact Us'"-->
                    </div>
                </div>

                <div class="w-full sm:w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">
                        <a class="text-xl sm:text-2xl font-medium">{{
                            footer?.socmed_header
                            }}</a>
                    </div>
                    <div class="flex gap-4">
                        <img class="w-20 h-15" alt="Brand logo" src="assets/images/logo/newlogo.png" />

                        <div class="hover:hover:cursor-pointer" *ngFor="let link of footer?.socmed_links">
                            <img alt="Brand logo" class="w-8 h-8" src="{{
                                    link?.socmed_image?.image?.asset?._ref
                                }}" />
                        </div>


                    </div>
                </div>
            </div>
            <div class="w-full py-2 flex gap-2 flex-wrap items-center justify-center">
                <span class="text-black">
                    {{footer?.copyright_text.replace('C','&copy;')}}

                </span>
                <span class="rounded-full h-1 w-1 bg-primaryNavyLight hidden sm:block"></span>
                <a class="text-black w-full sm:w-auto">
                    Terms & Conditions
                </a>
                <!-- pathDirective="{{footer?.termsandconditions_link?.termsandconditions_url}}" -->
                <span class="rounded-full h-1 w-1 bg-primaryNavyLight hidden sm:block"></span>
                <a class="text-black w-full sm:w-auto">
                    Privacy Policy
                </a>
                <!-- pathDirective="{{footer?.privacypolicy_link?.privacypolicy_url}}" -->
            </div>
        </div>
    </div>
</div>